<template>
  <div class="app-footer">
    <div class="app-footer-wrapper">
      <div class="app-footer-content">
        <div class="app-footer-top">
          <div class="app-footer-logo">
            <div class="app-footer-logo-wrapper">
              <!---->
              <div class="app-footer-logo-container" @click="toAbout">
                <div class="app-footer-logo-icon">
                  <BaseSpriteIcon
                    :icon-name="
                      brandName === 'WholeReader'
                        ? 'ico-logo-ffa'
                        : 'ico-logo-ocean'
                    "
                  />
                </div>
                <BaseSpriteIcon
                  custom-class="app-footer-logo-text"
                  :viewBox="
                    brandName === 'WholeReader' ? '0 0 160 21' : '0 0 156 26'
                  "
                  :icon-name="
                    brandName === 'WholeReader'
                      ? 'pic-app-name-ffa'
                      : 'pic-app-name-ocean'
                  "
                />
              </div>
              <div class="soc-links mt-4">
                <div v-for="(item, index) in socialLinks" :key="index">
                  <IRBaseButton
                    elevation="0"
                    fab
                    :href="item.link"
                    nuxt
                    color="#0F172A"
                    :class="item.name"
                    :aria-label="item.name"
                    @click.prevent="openUrlInOnlineOnly(item.link)"
                  >
                    <BaseSpriteIcon :icon-name="item.icon" />
                  </IRBaseButton>
                </div>
              </div>
              <div v-if="!isDevice && !isBlogApp" class="donation-button mt-4">
                <DonationControl
                  color="var(--primary-color)"
                  text-color="#fff"
                  large
                  :control-label="donateLabel"
                  :control-icon="'ico-fav-filled'"
                />
              </div>
            </div>
          </div>
          <div class="app-footer-text">
            <div class="app-footer-links">
              <ul>
                <li>
                  <IRBaseButton
                    elevation="0"
                    text
                    plain
                    small
                    @click="toLibrary"
                  >
                    Library
                  </IRBaseButton>
                </li>
                <li v-if="isPurchaseAvailable">
                  <NuxtLink :to="pricing">
                    Pricing
                  </NuxtLink>
                </li>
                <li v-if="!isBlogApp">
                  <NuxtLink
                    :to="compilations"
                    class="ir-btn ir-btn-small ir-btn-text ir-btn-plain"
                  >
                    Compilations
                  </NuxtLink>
                </li>
                <li v-if="isOcean">
                  <IRBaseButton
                    elevation="0"
                    text
                    plain
                    small
                    @click="toSearch"
                  >
                    Search
                  </IRBaseButton>
                </li>
                <li v-else>
                  <IRBaseButton elevation="0" text plain small @click="toAbout">
                    About
                  </IRBaseButton>
                </li>
                <li v-if="false">
                  <NuxtLink :to="helpCenter">
                    Help center
                  </NuxtLink>
                </li>
                <li>
                  <IRBaseButton elevation="0" text plain small @click="toFAQ">
                    F.A.Q.
                  </IRBaseButton>
                </li>
              </ul>
              <ul v-if="false">
                <li>
                  <NuxtLink :to="forTeachers">
                    For teachers
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="forParents">
                    For parents
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink :to="forStudents">
                    For students
                  </NuxtLink>
                </li>
              </ul>
              <ul>
                <li v-if="isOcean">
                  <IRBaseButton elevation="0" text plain small @click="toAbout">
                    About
                  </IRBaseButton>
                </li>
                <li v-else-if="!isBlogApp">
                  <NuxtLink
                    v-if="isOnline"
                    class="ir-btn ir-btn-small ir-btn-text ir-btn-plain"
                    :to="assessment"
                  >
                    Discover your reading level
                  </NuxtLink>
                  <a v-else @click="$_showOfflineErrorToaster">
                    Discover your reading level
                  </a>
                </li>
                <li>
                  <a
                    id="articles-link"
                    :href="blogLink"
                    class="ir-btn ir-btn-small ir-btn-text ir-btn-plain"
                    :class="{
                      disabled: !isOnline,
                      'ir-btn': true,
                      'ir-btn-text': true,
                      'ir-btn-small': true,
                      'ir-btn-plain': true
                    }"
                    rel="noopener noreferrer"
                    @click.prevent="openBlog"
                  >
                    Articles
                  </a>
                </li>
                <li>
                  <IRBaseButton
                    elevation="0"
                    text
                    plain
                    small
                    @click="openContactUs"
                  >
                    Contact us
                  </IRBaseButton>
                </li>
                <!-- uncomment and remove class="d-none" when blogger functionality is needed -->
                <!-- <li class="d-none">
                  <NuxtLink :to="blog">
                    Blog
                  </NuxtLink>
                </li> -->
              </ul>
              <div
                v-if="!isDevice"
                class="app-footer-install-link mt-2"
                :disabled="!osArtifactLink"
              >
                <client-only>
                  <div class="app-footer-install-link-title">Install App</div>
                  <div class="app-footer-install-app-link">
                    <a
                      :href="osArtifactLink"
                      class="app-footer-download-link"
                      @click.prevent="installApp"
                    >
                      <BaseSpriteIcon :icon-name="osImage" />
                      {{ downloadButtonText }}&nbsp;<span>{{ osLabel }}</span>
                    </a>
                  </div>
                </client-only>
              </div>
            </div>
          </div>
        </div>
        <div class="app-footer-bottom">
          <div class="bottom-side">
            <div
              class="app-footer-bottom-text"
              v-html="appFooterBottomText"
            ></div>
            <div v-if="!isDevice" class="footer-bottom-links">
              <a
                v-if="showTermOfUser"
                :href="thermOfUseLink"
                @click.prevent="openUrlInOnlineOnly(thermOfUseLink)"
              >
                Terms of Use
              </a>
              <a
                :href="privacyPolicyLink"
                @click.prevent="openUrlInOnlineOnly(privacyPolicyLink)"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div class="app-footer-go-top-link">
            <IRBaseButton
              elevation="0"
              class="font-weight-regular"
              @click="scrollToTop"
            >
              Back to top
              <BaseSpriteIcon :icon-name="'ico-arrow-chevron'" />
            </IRBaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import IRBaseButton from '@/components/base/IRBaseButton/IRBaseButton.vue';

import { mapGetters } from 'vuex';

import aboutViewMixin from '@/components/mixins/aboutViewMixin';

import BrandsEnum from '@/enums/BrandsEnum';
import OSTypesEnum from '@/enums/OSTypesEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import DonationControl from '@/components/controls/DonationControl/DonationControl.vue';

export default {
  name: 'AppFooter',
  //! DO NOT use components that use vuetify or inherit other components that use vuetify
  components: {
    BaseSpriteIcon,
    IRBaseButton,
    DonationControl
  },
  mixins: [aboutViewMixin],
  data() {
    const thermOfUseLink = this.$store.getters['ContextStore/getTermOfUseLink'];
    const privacyPolicyLink = this.$store.getters[
      'ContextStore/getPrivacyPolicyLink'
    ];
    const blogLink = this.$store.getters['ContextStore/getClientBlogUrl'];

    return {
      thermOfUseLink,
      privacyPolicyLink,
      library: { name: AppStateEnum.MANAGE_PUBLICATION },
      about: { name: AppStateEnum.MANAGE_ABOUT },
      pricing: { name: AppStateEnum.PRICING },
      blogLink,
      compilations: { name: AppStateEnum.MANAGE_COMPILATION },
      blog: { name: AppStateEnum.BLOG },
      search: { name: AppStateEnum.SEARCH },
      assessment: { name: AppStateEnum.ASSESSMENT },
      forParents: { name: AppStateEnum.FOR_PARENTS },
      forStudents: { name: AppStateEnum.FOR_STUDENTS },
      forTeachers: { name: AppStateEnum.FOR_TEACHERS },
      helpCenter: { name: AppStateEnum.HELP_CENTER },
      faq: { name: AppStateEnum.FREQUENTLY_ASKED_QUESTIONS },
      socialLinksFfa: [
        {
          name: 'instagram',
          link: 'https://www.instagram.com/wholereaderapp/',
          icon: 'ico-soc-instagram'
        },
        {
          name: 'twitter',
          link: 'https://twitter.com/WholeReaderApp',
          icon: 'ico-soc-twitter'
        },
        {
          name: 'facebook',
          link: 'https://www.facebook.com/WholeReaderApp',
          icon: 'ico-facebook-fill'
        },
        {
          name: 'telegram',
          link: 'https://t.me/wholereader',
          icon: 'ico-telegram-solid'
        },
        {
          name: 'youtube',
          link: 'https://www.youtube.com/@wholereader',
          icon: 'ico-youtube'
        }
      ],
      socialLinksOcean: [
        {
          name: 'instagram',
          link: 'https://www.instagram.com/oceanreaderapp/',
          icon: 'ico-soc-instagram'
        },
        {
          name: 'facebook',
          link: 'https://www.facebook.com/oceanreaderapp',
          icon: 'ico-facebook-fill'
        },
        {
          name: 'telegram',
          link: 'https://t.me/OceanLibrary_2_0',
          icon: 'ico-telegram-solid'
        },
        {
          name: 'youtube',
          link: 'https://www.youtube.com/@OceanReader/',
          icon: 'ico-youtube'
        }
      ],
      donateLabel: this.$t('App.Button.label.Donate')
    };
  },
  computed: {
    ...mapGetters('ContextStore', {
      OS: 'getPlatform',
      isOnline: 'isOnline',
      isElectron: 'isElectron',
      isDevice: 'isDevice'
    }),
    ...mapGetters('ContextStore', [
      'isDevice',
      'brandName',
      'isPurchaseAvailable'
    ]),
    showTermOfUser() {
      return !this.isDevice && !this.isOcean;
    },
    osArtifactLink() {
      return this.osArtifactUrlDetails.url;
    },
    osImage() {
      return this.osArtifactUrlDetails.iconName;
    },
    osLabel() {
      return this.OS.toLowerCase() !== OSTypesEnum.OSX ? this.OS : 'macOS';
    },
    downloadButtonText() {
      let text;
      if (this.OS.toLowerCase() === OSTypesEnum.IOS || OSTypesEnum.ANDROID) {
        text = this.$t('AboutViewOcean.app.for');
      } else {
        text = this.$t('AboutViewOcean.installer.for');
      }
      return text;
    },
    fullYear() {
      return new Date().getFullYear();
    },
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    },
    isOcean() {
      const brand = this.$store.getters['ContextStore/brand'];
      return brand === BrandsEnum.OCEAN;
    },
    clientReaderUrl() {
      return this.$store.getters['ContextStore/getClientReaderUrl'];
    },
    appFooterBottomText() {
      return `&copy; 2023-<span id="currentYear">${
        this.fullYear
      }</span>&nbsp;${this.$t('ProductName')}. All rights reserved`;
    },
    socialLinks() {
      return this.isOcean ? this.socialLinksOcean : this.socialLinksFfa;
    }
  },
  methods: {
    toLibrary() {
      this.$_goTo(AppStateEnum.MANAGE_PUBLICATION, 'library/');
    },
    toAbout() {
      this.$_goTo(AppStateEnum.MANAGE_ABOUT, 'about/');
    },
    toSearch() {
      this.$_goTo(AppStateEnum.SEARCH, 'search/');
    },
    toFAQ() {
      this.$_goTo(AppStateEnum.FREQUENTLY_ASKED_QUESTIONS, 'faq/');
    },
    $_goTo(name, path) {
      if (this.isBlogApp) {
        this.$_openUrl(`${this.clientReaderUrl}${path}`);
        return;
      }
      this.$router.push({ name });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    openContactUs() {
      if (!this.isOnline) {
        this.$_showOfflineErrorToaster();
        return;
      }

      if (this.isBlogApp) {
        return this.$emit('click:contact-us');
      }

      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CONTACT_US_POPUP,
        popupContext: {}
      });
    },
    openBlog() {
      if (!this.isOnline) {
        this.$_showOfflineErrorToaster();
        return;
      }

      this.$_openUrl(this.blogLink);
    },
    installApp() {
      if (!this.isOnline) {
        this.$_showOfflineErrorToaster();
        return;
      }

      this.$_openUrl(this.osArtifactLink, '_self');
    },
    openUrlInOnlineOnly(url, openTarget) {
      if (!this.isOnline) {
        this.$_showOfflineErrorToaster();
        return;
      }
      this.$_openUrl(url, openTarget);
    },
    $_showOfflineErrorToaster() {
      if (this.isBlogApp) {
        return this.$emit(
          'error-message',
          this.$t('Toaster.noInternetConnection.error.text')
        );
      }
      this.$store.dispatch('ManagePopupStore/openErrorToaster', {
        text: this.$t('Toaster.noInternetConnection.error.text'),
        toasterIcon: 'ico-no-wifi'
      });
    },
    $_openUrl(url, openTarget) {
      this.$store.dispatch('ContextStore/openUrl', {
        url,
        openTarget
      });
    }
  }
};
</script>

<style lang="less" src="./AppFooter.less"></style>
