<template>
  <component
    :is="getComponentTag"
    v-bind="bindProps"
    ref="button"
    :title="title"
    :aria-label="ariaLabel"
    :target="target"
    :class="buttonClasses"
    :style="buttonStyles"
    @mousedown="startRippleEffect"
    @click="click"
    @touchstart="touchstart"
    @touchend="touchend"
  >
    <slot />
    <span v-if="ripple" class="ir-ripple" :style="ripple.style"></span>
  </component>
</template>

<script>
export default {
  name: 'IRBaseButton',
  props: {
    elevation: [String, Number],
    fab: Boolean,
    xSmall: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean,
    xxLarge: Boolean,
    elevated: Boolean,
    flat: Boolean,
    tonal: Boolean,
    outlined: Boolean,
    text: Boolean,
    plain: Boolean,
    color: String,
    textColor: String,
    title: String,
    target: String,
    ariaLabel: String,
    rounded: Boolean,
    block: Boolean,
    nuxt: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ripple: null
    };
  },
  computed: {
    bindProps() {
      const props = {};
      if (this.nuxt && this.to) {
        props.to = this.to;
      }
      if (this.nuxt && this.href) {
        props.href = this.href;
      }
      return props;
    },
    getComponentTag() {
      if (this.nuxt) {
        return this.to ? 'NuxtLink' : 'a';
      }
      return 'button';
    },
    buttonClasses() {
      return {
        //base
        'ir-btn': true,
        // sizes
        'ir-btn-x-small': this.xSmall,
        'ir-btn-small': this.small,
        'ir-btn-large': this.large,
        'ir-btn-x-large': this.xLarge,
        'ir-btn-xx-large': this.xxLarge,
        // variants
        'ir-btn-elevated': this.elevated,
        'ir-btn-flat': this.flat,
        'ir-btn-tonal': this.tonal,
        'ir-btn-outlined': this.outlined,
        'ir-btn-text': this.text,
        'ir-btn-plain': this.plain,
        // other
        'ir-btn-fab': this.fab,
        'ir-btn-rounded': this.rounded,
        'ir-btn-block': this.block
      };
    },
    buttonStyles() {
      const styles = {
        '--button-color': this.color || 'inherit',
        '--text-color': this.textColor || 'inherit',
        '--button-elevation': this.elevation
          ? `${this.elevation}px ${this.elevation * 2}px rgba(0, 0, 0, 0.2)`
          : 'none'
      };

      if (this.text) {
        styles.backgroundColor = 'transparent';
        styles.color = this.color;
      }

      return styles;
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    },
    touchstart(e) {
      return this.$emit('touchstart', e);
    },
    touchend(e) {
      return this.$emit('touchend', e);
    },
    startRippleEffect(event) {
      const button = this.$refs.button;
      const rect = button.getBoundingClientRect();
      const size = Math.max(rect.width, rect.height);
      const style = {
        top: `${event.clientY - rect.top - size / 2}px`,
        left: `${event.clientX - rect.left - size / 2}px`,
        width: `${size}px`,
        height: `${size}px`
      };
      this.ripple = { style };
      this.clearRipple();
    },
    clearRipple() {
      setTimeout(() => {
        setTimeout(() => {
          this.ripple = null;
        }, 1000);
      }, 100);
    }
  }
};
</script>

<style lang="less">
:root {
  --button-height: 36px;
  --button-font-size: 14px;
  --button-border-radius: 8px;
  --button-padding: 0 16px;

  --button-height-x-small: 24px;
  --button-font-size-x-small: 12px;
  --button-border-radius-x-small: 4px;
  --button-padding-x-small: 0 8px;

  --button-height-small: 32px;
  --button-font-size-small: 14px;
  --button-padding-small: 0 12px;

  --button-height-large: 40px;
  --button-font-size-large: 14px;
  --button-padding-large: 0 16px;

  --button-height-x-large: 48px;
  --button-font-size-x-large: 16px;
  --button-padding-x-large: 0 20px;

  --button-height-xx-large: 52px;
  --button-font-size-xx-large: 18px;
  --button-padding-xx-large: 0 24px;

  --button-fab-size-x-small: 24px;
  --button-fab-size-small: 36px;
  --button-fab-size-default: 40px;
  --button-fab-size-large: 56px;
  --button-fab-size-x-large: 64px;
  --button-fab-size-xx-large: 72px;
}

.ir-ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  transform: scale(0);
  animation: ripple-effect 400ms linear forwards;
}

@keyframes ripple-effect {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.ir-btn {
  position: relative;
  overflow: hidden;
  outline: 0;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  padding: var(--button-padding);
  height: var(--button-height);
  font-size: var(--button-font-size);
  background-color: var(--button-color, 'initial');
  color: var(--text-color, 'initial');
  box-shadow: var(--button-elevation, none);
  border-radius: var(--button-border-radius);

  &:not(.ir-btn-plain):hover:before {
    opacity: 0.08;
  }

  &:not(:disabled):before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  svg {
    fill: currentColor;
  }
}

.ir-btn-rounded {
  border-radius: calc(var(--button-height) / 2);
}

.ir-btn-x-small {
  height: var(--button-height-x-small);
  font-size: var(--button-font-size-x-small);
  border-radius: var(--button-border-radius-x-small);
  padding: var(--button-padding-x-small);
}

.ir-btn-small {
  height: var(--button-height-small);
  font-size: var(--button-font-size-small);
  padding: var(--button-padding-small);
}

.ir-btn-large {
  height: var(--button-height-large);
  font-size: var(--button-font-size-large);
  padding: var(--button-padding-large);
}

.ir-btn-x-large {
  height: var(--button-height-x-large);
  font-size: var(--button-font-size-x-large);
  padding: var(--button-padding-x-large);
}

.ir-btn-xx-large {
  height: var(--button-height-xx-large);
  font-size: var(--button-font-size-xx-large);
  padding: var(--button-padding-xx-large);
}

.ir-btn-fab {
  width: var(--button-fab-size-default);
  height: var(--button-fab-size-default);
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.ir-btn-fab.ir-btn-x-small {
  width: var(--button-fab-size-x-small);
  height: var(--button-fab-size-x-small);
}

.ir-btn-fab.ir-btn-small {
  width: var(--button-fab-size-small);
  height: var(--button-fab-size-small);
}

.ir-btn-fab.ir-btn-large {
  width: var(--button-fab-size-large);
  height: var(--button-fab-size-large);
}

.ir-btn-fab.ir-btn-x-large {
  width: var(--button-fab-size-x-large);
  height: var(--button-fab-size-x-large);
}

.ir-btn-fab.ir-btn-xx-large {
  width: var(--button-fab-size-xx-large);
  height: var(--button-fab-size-xx-large);
}

.ir-btn-outlined {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px currentColor;
  color: var(--button-color, 'initial');
}

.ir-btn-block {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  max-width: none;
  min-width: 100%;
}

.ir-btn-elevated,
.ir-btn-flat,
.ir-btn-tonal,
.ir-btn-outlined,
.ir-btn-text,
.ir-btn-plain {
  /* TODO */
}
</style>
